<template>
  <v-container
    id="alerts"
    fluid
    tag="section"
    class="fondo"
  >
    <v-dialog
      v-model="dialogOpciones"
      max-width="750"
      width="95%"
    >
      <v-card
        class="mx-auto"
      >
        <div
          class="d-flex flex-column justify-space-between align-center"
        >
          <v-img
            src="assets/banner.png"
            max-width="250"
            @click="$router.push(
              { path: '/data' },
              () => {
                $emit('reload')
              },
            )"
          />
        </div>
        <v-simple-table dense>
          <thead>
            <tr>
              <th class="primary--text text-center text-h4 text-uppercase">
                Módulos del Data Judicial
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="link in links"
              :key="link.titulo"
              @click="$router.push(
                { path: `${ link.link }` },
                () => {
                  $emit('reload')
                },
              )"
            >
              <v-avatar
                tile
                small
                color="teal"
              >
                <v-icon dark>
                  {{ link.icono }}
                </v-icon>
              </v-avatar>
              <strong class="text-uppercase"> {{ link.titulo }}</strong> - {{ link.descripcion }}
              <v-divider />
            </tr>
          </tbody>
        </v-simple-table>
        <!--         <div
          v-for="link in links"
          :key="link.titulo"
          class="text--primary"
        >
          <div
            class="ms-2 mt-2 mr-2"
            @click="$router.push(
              { path: `${ link.link }` },
              () => {
                $emit('reload')
              },
            )"
          >
            <v-card-text class="text-subtitle-2 mt-n4 mb-n4 ms-n2">
              <v-avatar
                tile
                small
                color="teal"
              >
                <v-icon dark>
                  {{ link.icono }}
                </v-icon>
              </v-avatar>
              <strong class="text-uppercase"> {{ link.titulo }}</strong> - {{ link.descripcion }}
            </v-card-text>
          </div>
          <v-divider class="mt-2" />
        </div> -->
      </v-card>
    </v-dialog>
    <v-overlay
      :value="carga"
      opacity="1"
    >
      <v-progress-circular
        indeterminate
        color="red"
        :size="200"
      />
    </v-overlay>
    <v-snackbar
      color="primary"
      bottom
    >
      {{ textoSnack }}
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snack = false"
        >
          cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <v-navigation-drawer
      touchless
      absolute
      width="100%"
      max-width="400"
      permanent
    >
      <div
        class="top-main d-flex flex-column align-center justify-center"
      >
        <v-img
          src="assets/logo_la_n.png"
          max-width="250"
        />
      </div>
      <v-row
        v-if="!carga"
        justify="center"
        no-gutters
      >
        <base-material-card
          color-back="rgba(90, 90, 90, .55)"
          color="primary"
          dark
          max-width="90%"
          width="600"
          icon="mdi-gavel"
          title="DATA JUDICIAL"
        >
          <div class="text-center">
            <div class="d-flex flex-column align-center justify-center">
              <v-switch
                v-model="switchBusqueda"
                inset
                label="Clic para buscar por juez"
              />
            </div>
            <v-autocomplete
              v-if="switchBusqueda===false"
              v-model="model"
              :items="juzgadosData"
              :loading="isLoading"
              :search-input.sync="search"
              hide-selected
              autofocus
              clearable
              item-text="nombre"
              item-value="idinstancia"
              no-data-text="No se encuentra información de juzgados"
              label="Busque el órgano jurisdiccional a consultar la producción"
              placeholder="Escriba o seleccione el juzgado"
              return-object
            />
            <v-autocomplete
              v-else
              v-model="model"
              :items="juzgadosData"
              :loading="isLoading"
              :search-input.sync="search"
              hide-selected
              autofocus
              clearable
              item-text="juez_juzgado"
              item-value="idinstancia"
              no-data-text="No se encuentra información de jueces"
              label="Busque el juez a consultar"
              placeholder="Escriba o seleccione el juez"
              return-object
            />
            <v-divider />
            <v-col
              cols="12"
              class="text-right"
            >
              <v-btn
                color="primary"
                :disabled="!model"
                @click="IrJuzgado(model.id)"
              >
                Consultar
                <v-icon right>
                  mdi-scale-balance
                </v-icon>
              </v-btn>
            </v-col>
          </div>

          <v-row
            justify="center"
            no-gutters
          >
            <v-btn
              color="white"
              plain
              class="my-1 mx-n2 boton"
              @click="$router.push(
                { path: `ranking` },
                () => {
                  $emit('reload')
                },
              )"
            >
              <v-icon left>
                mdi-podium
              </v-icon>RANKING DE PRODUCCIÓN JURISDICCIONAL
            </v-btn>
          </v-row>
          <v-row
            justify="center"
            no-gutters
          >
            <v-btn
              color="white"
              plain
              class="my-1 mx-n2 boton"
              @click="dialogOpciones = true"
            >
              <v-icon left>
                mdi-plus
              </v-icon> <strong>MÓDULOS DEL DATA JUDICIAL</strong>
            </v-btn>
            <!--  <v-btn
              v-for="link in links"
              :key="link"
              color="teal"
              class="my-2"
            >
              {{ link }}
            </v-btn> -->
          </v-row>
          <br>
          <div
            class="text-body-2 white--text text-center"
            @click="$router.push('/cerrar_sesion')"
          >
            {{ new Date().getFullYear() }} &copy; CSJLA
          </div>
        </base-material-card>
      </v-row>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
  import axios from 'axios'
  // import $ from 'jquery'
  // const lodash = require('lodash')
  export default {
    name: 'Menu',
    data: () => ({
      links: [
        // { titulo: 'Ranking de producción jurisdiccional', link: 'ranking', icono: 'mdi-podium', descripcion: 'Reporte por puestos de los juzgados en base a su producción y meta' },
        { titulo: 'Audiencias', link: 'audiencia', icono: 'mdi-webcam', descripcion: 'Audiencias y sus estados por órganos jurisdiccionales o usuario' },
        { titulo: 'Escritos', link: 'escrito', icono: 'mdi-file-document-multiple-outline', descripcion: 'Escritos y sus estados por órgano jurisdiccional o secretario' },
        { titulo: 'Secretarios', link: 'secretario', icono: 'mdi-chart-line', descripcion: 'Descargos de secretarios en el SIJ' },
        { titulo: 'Notificaciones', link: 'notificaciones', icono: 'mdi-send-check', descripcion: 'Cantidad de notificaciones electrónicas y físicas' },
        { titulo: 'Carga', link: 'ingresos', icono: 'mdi-file-document-arrow-right', descripcion: 'Carga de órganos jurisdiccionales (motivo de ingreso, estado, etc)' },
        { titulo: 'Impulso (penal)', link: 'impulso', icono: 'mdi-clock-alert', descripcion: 'Expedientes sin impulso procesal' },
        { titulo: 'Delitos', link: 'delito', icono: 'mdi-handcuffs', descripcion: 'Dashboard de delitos con juzgados, ubicacion, estado' },
        { titulo: 'Boletines', link: 'boletin', icono: 'mdi-account-search', descripcion: 'Reporte de boletines y su estado' },
      ],
      verPDF: false,
      pdfSource: '',
      dialogOpciones: false,
      isLoading: false,
      load: false,
      carga: true,
      model: null,
      search: null,
      items: [],
      juzgadosData: [],
      ver: true,
      j: [],
      itemsEsp: [],
      actualYear: '',
      dataCat: [],
      dataSubcat: [],
      url: '',
      sedeCorteData: '',
      listaSuperiores: [],
      listaEspecializados: [],
      listaMixtos: [],
      listaPazLetrado: [],
      listaJuzgados: [],
      arrayFinal: [],
      x: 0,
      snack: false,
      textoSnack: '',
      switchBusqueda: false,
    }),
    created () {
      // this.leerWeb()
      this.dataUsuario = JSON.parse(localStorage.getItem('usuarioDataCSJLA'))
      if (!this.dataUsuario) {
        localStorage.removeItem('usuarioDataCSJLA')
        this.$router.push('/')
      }
      this.textoSnack = this.dataUsuario.nombre + ', bienvenido al Data Judicial, puede consultar la producción de los órganos jurisdiccionales, así como el puesto en producción a nivel de toda la sede judicial, las audiencias realizadas, escritos y descargos en el SIJ'
      this.snack = true
      this.$store.commit('SET_VALOR', true)
      this.sedeCorteData = localStorage.getItem('sedeCorteData')
      this.url = `${window.__env.dataCfg.urlApiData}juzgados_disponibles/${this.sedeCorteData}/`
      this.leerJuzgados()
    },
    methods: {
      IrJuzgado (id) {
        this.$router.push(`/prod/${id}_${this.sedeCorteData}_${new Date().getFullYear()}/`)
      },
      leerJuzgados () {
        axios
          .get(
            this.url,
          )
          .then((response) => {
            this.juzgadosData = response.data.array
            this.carga = false
            const promise1 = new Promise((resolve, reject) => {
            })
            promise1.then((value) => {
              this.carga = false
            })
          })
          .catch((e) => {
          console.log(e); // eslint-disable-line
          })
      },
    },
  }
</script>
<style lang="sass">
.v-card
  backdrop-filter: blur(10px)
  -webkit-backdrop-filter: blur(10px)
  border: 1px solid rgba(255, 255, 255, .4)

.boton:hover,
.boton:focus
  transform: translate(0,0)
  opacity: 1
  color: white
  text-shadow: 0 0 20px white
  transform: translateY(-0.55em)
  box-shadow: inset 0 -3.25em 0 0 var(--hover)

</style>
